import { createTheme } from '@mui/material/styles';

import { elevation } from '../mixins';
import { shadow, background } from '../elevation';
import { blue, darkBlue, neutral, red, surface } from '../colors';

const theme = createTheme({
  commandAlkon: {
    components: {
      Menu: {
        background: background.dark[5],
      },
      MenuItem: {
        highlightBackground: blue[90],
        highlightColor: neutral[40],
        hoverBackground: neutral[40],
      },
      FavoriteButton: {
        color: blue['90'],
      },
      Button: {
        background: neutral[10],
        hoverBackground: surface.dark[2],
        pressedBackground: blue[90],
        activeColor: darkBlue[10],
        elevation: elevation({
          shadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
          filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3))',
        }),
      },
    },
    elevation: {
      1: elevation({ background: background.dark[1], shadow: shadow.dark[1] }),
      2: elevation({ background: background.dark[2], shadow: shadow.dark[2] }),
      3: elevation({ background: background.dark[3], shadow: shadow.dark[3] }),
      4: elevation({ background: background.dark[4], shadow: shadow.dark[4] }),
      5: elevation({ background: background.dark[5], shadow: shadow.dark[5] }),
    },
  },

  palette: {
    mode: 'dark',
    text: {
      primary: neutral[95],
    },
    primary: {
      main: blue[90],
      contrastText: darkBlue[10],
    },
    secondary: {
      main: blue[95],
      contrastText: blue[10],
    },
    error: {
      main: red[80],
      contrastText: neutral[20],
    },
  },
  shadows: {
    1: shadow.dark[1],
    2: shadow.dark[2],
    3: shadow.dark[3],
    4: shadow.dark[4],
    5: shadow.dark[5],
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: background.dark[5],
          shadow: shadow.dark[5],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${blue[95]}`,
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${blue[95]}`,
          },
        },
      ],
    },
    MuiItem: {
      styleOverrides: {
        root: {
          color: neutral[95],
        },
      },
    },
  },
  typography: {
    fontFamily: 'Work Sans',
    fontSize: 14,
  },
});

export default theme;
