import { css } from '@emotion/react';

export default ({ theme }) => css`
  height: 100%;
  display: grid;
  grid-template-rows: [header] auto [widgets] 1fr;

  header {
    display: flex;
    align-items: center;
    padding: 5px 0;

    nav {
      display: flex;
      flex: 1;
      justify-content: end;
      min-height: 56px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: ${theme.spacing(3)};

        li {
          display: flex;
          align-items: center;
        },
      },
    },
`;
