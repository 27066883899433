import { css } from '@emotion/react';

const pressedStyle = ({ theme }) => css`
  background: ${theme.commandAlkon.components.Button.pressedBackground};
  color: ${theme.commandAlkon.components.Button.activeColor};
  ${theme.commandAlkon.components.Button.elevation};
`;

const defaultActiveStyle = ({ theme }) => css`
  background: ${theme.commandAlkon.components.Button.pressedBackground};
  color: ${theme.commandAlkon.components.Button.activeColor};
`;

export const ButtonStyle = ({ theme, defaultActive, pressed }) => css`
  display: flex;
  gap: ${theme.spacing(2)};
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-size: 20px;
  border-radius: 100px;
  background: ${theme.commandAlkon.components.Button.background};
  padding: 10px 24px;
  font-weight: 500;
  box-sizing: border-box;
  height: 40px;

  &:hover {
    background: ${theme.commandAlkon.components.Button.hoverBackground};
  }

  ${defaultActive ? defaultActiveStyle({ theme }) : ''}

  ${pressed ? pressedStyle({ theme }) : ''}
`;
