import Button from '@mui/material/Button';
import styled from '@emotion/styled';

import { ButtonStyle } from './style';

const Styled = styled(Button, { shouldForwardProp: prop => prop !== 'pressed' && prop !== 'defaultActive' })`
  ${ButtonStyle}
`;

export default Styled;
