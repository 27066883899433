import { createTheme } from '@mui/material/styles';

import { elevation } from '../mixins';
import { shadow, background } from '../elevation';
import { blue, darkBlue, neutral, red, surface } from '../colors';

const theme = createTheme({
  commandAlkon: {
    components: {
      Menu: {
        background: neutral[100],
      },
      MenuItem: {
        highlightBackground: blue[95],
        highlightColor: neutral[40],
        hoverBackground: neutral[95],
      },
      FavoriteButton: {
        color: darkBlue['10'],
      },
      Button: {
        background: neutral[100],
        hoverBackground: neutral[95],
        pressedBackground: blue[95],
        activeColor: neutral[40],
        elevation: elevation({
          shadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
          filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))',
        }),
      },
    },
    elevation: {
      1: elevation({ background: background.light[1], shadow: shadow.light[1] }),
      2: elevation({ background: background.light[2], shadow: shadow.light[2] }),
      3: elevation({ background: background.light[3], shadow: shadow.light[3] }),
      4: elevation({ background: background.light[4], shadow: shadow.light[4] }),
      5: elevation({ background: background.light[5], shadow: shadow.light[5] }),
    },
  },

  palette: {
    mode: 'light',
    text: {
      primary: neutral[40],
    },
    primary: {
      main: darkBlue[10],
      contrastText: neutral[100],
    },
    error: {
      main: red[80],
      contrastText: neutral[20],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            padding: '10px 24px',

            '&:hover': {
              background: surface.light[1],
            },
            '&:focus': {
              background: surface.light[2],
            },
            '&:active': {
              background: surface.light[3],
            },
            '&:disabled': {
              color: neutral[10],
              opacity: 0.38,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: neutral[40],
            border: `1px solid ${neutral[40]}`,
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${neutral[40]}`,
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: neutral[10],
          fontWeight: 600,
          '&.Mui-focused': {
            color: darkBlue[50],
          },
          '&.Mui-error': {
            color: red[50],
          },
          '&.Mui-disabled': {
            color: neutral[80],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${darkBlue[50]}`,
            },
          },
          '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${red[50]}`,
            },
          },
          '&.Mui-disabled': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: neutral[90],
            },
          },
        },
        notchedOutline: {
          border: `1px solid ${neutral[70]}`,
          borderRadius: '4px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Work Sans',
    fontSize: 14,
  },
});

export default theme;
