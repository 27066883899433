import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as FavoriteIcon } from '../../../../assets/icons/Favorite.svg';
import { ReactComponent as FavoriteOutlinedIcon } from '../../../../assets/icons/FavoriteOutlined.svg';
import style from './style';

const Styled = styled(IconButton, { shouldForwardProp: prop => prop !== 'pressed' })`
  ${style}
`;

const FavoriteButton = ({ isFavorite = false, onClick }) => {
  return (
    <Styled variant="outlined" onClick={onClick} pressed={isFavorite}>
      {isFavorite ? (
        <SvgIcon component={FavoriteIcon} inheritViewBox fontSize="small" />
      ) : (
        <SvgIcon component={FavoriteOutlinedIcon} inheritViewBox fontSize="small" />
      )}
    </Styled>
  );
};

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FavoriteButton;
