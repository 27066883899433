import React from 'react';
import { API, ConnexDesignSystem, Core } from 'connex-cds';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import messages from './i18n';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from './views/authenticated-routes/RedirectToEntity';
import Dashboard from './views/Dashboard';
import ThemeProvider from './providers/ThemeProvider';
import { ROUTES } from './util/constants';
import MuiXLicense from './commons/MuiXLicense';

API.setSource('dashboards-exp');

const ONE_HOUR = 1000 * 60 * 60;
const TWELVE_HOURS = ONE_HOUR * 12;

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000, cacheTime: TWELVE_HOURS, refetchOnWindowFocus: false } },
});

const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Core.Spinner spin={isConfiguring}>
          <ConnexDesignSystem messages={messages} appId="dashboards-exp" breakpoints={[950]}>
            <ThemeProvider>
              <Routes>
                <Route path="" element={<RedirectToEntity />}>
                  <Route path="/:entityRef/*">
                    <Route path="" element={<AuthenticatedRoutes />}>
                      <Route path="" element={<Navigate to={ROUTES.DASHBOARD} />} />
                      <Route
                        path={ROUTES.DASHBOARD}
                        element={
                          <>
                            <Dashboard />
                            <MuiXLicense />
                          </>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </ThemeProvider>
          </ConnexDesignSystem>
        </Core.Spinner>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
