import { API } from '../api';

export const urlParts = hostname => {
  /*
  This approach will only work under the specific circumstances
  that presently exist.  A tld such as co.uk, the addition of a port number or a
  multiple-level subdomain will break things.
   */
  const parts = hostname?.split?.('.');

  if (parts?.length !== 4) {
    throw new Error(`Invalid hostname: ${hostname}`);
  }

  return {
    service: parts[0],
    region: parts[1],
    secondLevelDomain: parts[2],
    topLevelDomain: parts[3],
  };
};

export const getApiHostname = (hostname, apiName) => {
  if (!hostname || !apiName) {
    throw new Error('Missing parameters');
  }

  const { service, region } = urlParts(hostname);

  let apiHostname = `api.${region}.commandalkon.io`;

  if (API.getSource() !== apiName) return apiHostname;

  if (service.includes('-')) {
    const [, environment] = service.split('-');
    apiHostname = `${environment}.${apiHostname}`;
  }
  return apiHostname;
};
