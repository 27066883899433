import { css } from '@emotion/react';

export const MenuItemStyle = ({ theme }) => css`
  display: flex;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2.25, 2)};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;

  &:hover {
    background: ${theme.commandAlkon.components.MenuItem.hoverBackground};
  }

  &.SelectableMenuItem__selected {
    font-weight: 500;
    background-color: ${theme.commandAlkon.components.MenuItem.highlightBackground};
    color: ${theme.commandAlkon.components.MenuItem.highlightColor};
  }
`;

export const MenuStyle = ({ theme }) => css`
  .MuiPaper-root {
    margin-top: ${theme.spacing(1.4)};
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    background: ${theme.commandAlkon.components.Menu.background};
  }
`;
