import { css } from '@emotion/react';

const pressedStyle = css`
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default ({ theme, pressed }) => css`
  width: 40px;
  height: 40px;
  padding: 10px;
  background: ${theme.commandAlkon.components.Button.background};
  color: ${theme.commandAlkon.components.FavoriteButton.color};

  &:hover {
    background: ${theme.commandAlkon.components.Button.hoverBackground};
  }

  ${pressed ? pressedStyle : ''}
`;
