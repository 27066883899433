import { PERMISSIONS, ROUTES } from './util/constants';
import { composeUrl } from './util/helpers';

export default [
  {
    id: ROUTES.DASHBOARD,
    labelStringId: ROUTES.DASHBOARD,
    path: composeUrl(ROUTES.DASHBOARD),
    icon: 'fa-light fa-square-question',
    permission: PERMISSIONS.PERM_1,
  },
];
