import { Localization } from 'connex-cds';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

import SelectableMenu from '../../../../commons/SelectableMenu';
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/DashboardDropdownIcon.svg';

const dashboardOptions = [
  {
    value: 'dashboardSelector_default',
    label: 'dashboardSelector_default',
  },
];

const dashboardActions = [
  {
    id: 'dashboardSelector_action_settings',
    icon: <SettingsSuggestIcon />,
    label: 'dashboardSelector_action_settings',
  },
  {
    id: 'dashboardSelector_action_share',
    icon: <FolderSharedIcon />,
    label: 'dashboardSelector_action_share',
  },
  {
    id: 'dashboardSelector_action_rename',
    icon: <DriveFileRenameOutlineRoundedIcon />,
    label: 'dashboardSelector_action_rename',
  },
  {
    id: 'dashboardSelector_action_delete',
    icon: <DeleteIcon />,
    label: 'dashboardSelector_action_delete',
  },
];

const DashboardSelector = ({ value, onChange }) => {
  return (
    <SelectableMenu
      value={value || dashboardOptions[0]}
      onChange={onChange}
      // This have the custom dashboardIcon but, there is some config pending to customize colors
      valueStartIcon={<SvgIcon component={DashboardIcon} inheritViewBox fontSize="inherit" />}
      options={dashboardOptions}
      renderOption={({ label }) => <Localization.Translate stringId={label} />}
      actions={dashboardActions}
      renderAction={({ label }) => <Localization.Translate stringId={label} />}
      withArrow
      withCheckMark
    />
  );
};

DashboardSelector.propTypes = {
  value: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  onChange: PropTypes.func,
};

export default DashboardSelector;
