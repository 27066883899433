import { useState } from 'react';
import { Localization } from 'connex-cds';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Button from '../Button';
import SelectableMenuItem from './SelectableMenuItem';
import SelectableMenuAction from './SelectableMenuAction';

import { MenuStyle } from './style';

const Styled = styled(Menu)`
  ${MenuStyle}
`;

const SelectableMenu = ({
  value,
  onChange,
  options,
  renderValue,
  renderOption,
  renderAction,
  actions,
  valueStartIcon,
  withArrow,
  withCheckMark,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(value || null);

  if (!selectedValue) {
    return null;
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = value => () => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
    handleClose();
  };

  const SelectButtonEndIcon = !!anchorEl ? (
    <ArrowDropUpIcon fontSize="inherit" />
  ) : (
    <ArrowDropDownIcon fontSize="inherit" />
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={valueStartIcon}
        endIcon={withArrow ? SelectButtonEndIcon : null}
        pressed={!!anchorEl}
        defaultActive
      >
        {renderValue ? (
          renderValue({
            selected: selectedValue,
          })
        ) : (
          <Localization.Translate stringId={selectedValue.label} />
        )}
      </Button>

      <Styled className="selectable-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {!!options?.length &&
          options.map(option => {
            const isSelected = option.value === selectedValue.value;
            return (
              <SelectableMenuItem
                key={option.value}
                option={option}
                withCheckMark={withCheckMark}
                isSelected={isSelected}
                onClick={handleSelect(option)}
                renderItemValue={renderOption}
              />
            );
          })}

        {!!actions?.length && <Divider />}
        {!!actions?.length &&
          actions.map((action, index) => (
            <SelectableMenuAction
              key={action.id || index}
              action={action}
              onClick={handleClose}
              renderAction={renderAction}
            />
          ))}
      </Styled>
    </>
  );
};

SelectableMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, icon: PropTypes.element, onClick: PropTypes.func })
  ),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
  renderAction: PropTypes.func,
  renderOption: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  valueStartIcon: PropTypes.element,
  withArrow: PropTypes.bool,
  withCheckMark: PropTypes.bool,
};

SelectableMenu.defaultProps = {
  actions: [],
  options: [],
  valueStartIcon: null,
  withArrow: false,
  withCheckMark: false,
};

export default SelectableMenu;
