import React, { useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Localization } from 'connex-cds';

import { supportedLocales } from '../../../../i18n/common';

const DateRangePicker = () => {
  const { userLocale } = Localization.useLocalizationContext();
  const translateMessage = Localization.useTranslateMessage();

  const localeText = useMemo(() => {
    return { start: translateMessage('datePicker_start'), end: translateMessage('datePicker_end') };
  }, [translateMessage]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={supportedLocales[userLocale]}>
      <MuiDateRangePicker format={supportedLocales?.[userLocale]?.formats?.L || 'MM/DD/YYYY'} localeText={localeText} />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
