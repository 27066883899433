import React, { useState } from 'react';
import styled from '@emotion/styled';

import DashboardSelector from './components/DashboardSelector';
import FavoriteButton from './components/FavoriteButton';
import CreateMenu from './components/CreateMenu';
import DateSelector from './components/DateSelector';
import DateRangePicker from './components/DateRangePicker';

import dashboardStyle from './style';

const Styled = styled.section`
  ${dashboardStyle}
`;

const Dashboard = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteDashboard = () => setIsFavorite(prevState => !prevState);

  return (
    <Styled className="dashboard">
      <header>
        {showCalendar && <DateRangePicker />}
        <nav>
          <ul>
            <li>
              <CreateMenu />
            </li>
            <li>
              <DateSelector handleShowCalendar={setShowCalendar} />
            </li>
            <li>
              <DashboardSelector />
            </li>
            <li>
              <FavoriteButton isFavorite={isFavorite} onClick={handleFavoriteDashboard} />
            </li>
          </ul>
        </nav>
      </header>
    </Styled>
  );
};

export default Dashboard;
