import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '22ea14ca0a454aeb72aeb91bd13be649Tz02ODk1OCxFPTE3MTg4MDI2MTM3NjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const MuiXLicense = () => {
  return null;
};

export default MuiXLicense;
