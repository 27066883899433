import { Theme } from 'connex-cds';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

import { darkTheme, lightTheme } from '../theme/modes';

const ThemeProvider = ({ children }) => {
  const { themeName } = Theme.useThemeContext();

  const theme = {
    dark: darkTheme,
    light: lightTheme,
  };

  return <MuiThemeProvider theme={theme[themeName]}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
